var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', {
    attrs: {
      "placement": _vm.placement,
      "modalDirection": _vm.modalDirection
    }
  }, [_c('div', {
    on: {
      "click": _vm.toggleMemberModal
    }
  }, [_c('AvatarCountBadge', {
    staticClass: "avatarCount",
    attrs: {
      "size": _vm.size,
      "fontSize": _vm.fontSize,
      "count": _vm.count
    }
  })], 1), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.modalExpanded ? _c('Modal', {
    staticClass: "modal",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "uppercase"
        }, [_vm._v(_vm._s(_vm.headerTitle))]), _c('XIcon', {
          on: {
            "click": _vm.toggleMemberModal
          }
        })];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return _vm._l(_vm.userData, function (data) {
          return _c('UserWrapperStyled', {
            key: data.id
          }, [_c('AvatarBadge', {
            attrs: {
              "size": '30px',
              "fontSize": '14px',
              "userData": data,
              "isOwner": data.isOwner
            }
          }), _c('UserStyled', [_c('div', {
            staticClass: "userName"
          }, [_vm._v(_vm._s(data.username))]), _c('div', {
            staticClass: "sub-text"
          }, [_vm._v(_vm._s(data.email))])]), data.isOwner ? _c('OwnerBadge') : _vm._e()], 1);
        });
      },
      proxy: true
    }], null, false, 1212635120)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }